import React from 'react'
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom'
import BasicExample from './Components/Navbar'
import Homepage from './Components/Homepage'
import About from './Components/About'
import Gallery from './Components/Gallery'
import Contact from './Components/Contact'
import Yard from './Components/Services/Yard'
import Navbars from './Components/Navbar'
import Warehouse from './Components/Services/Warehouse'
import Transportation from './Components/Services/Transportation'

function App() {
  return (
    <>
    <BrowserRouter>
    <Navbars/>
    <Routes>
      <Route path='/' element={<Homepage/>} />
      <Route path='/about' element={<About/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/yard'  element={<Yard/>}/>
      <Route path='/warehouse' element={<Warehouse/>}/>
      <Route path='/transportation' element={<Transportation/>}/>
      <Route path='/offered' element={<Warehouse/>}/>

    </Routes>   
    </BrowserRouter>
      
    </>
  )
}

export default App
