import React from 'react'
import Footer from '../Footer'
import { Container, Row, Col, Carousel } from 'react-bootstrap';
function Transportation() {

    const service = [
        {
            id: 1, name: "Warehouse", links: [
                { name: 'Warehouse', link: '/warehouse' },
                { name: "Yard", link: '/yard' },
                { name: 'Transportation', link: '/transportation' },
                { name: 'Offered', link: '/offered' },


            ]
        }
    ]

    const images = [
        { image: "/assets/allimages/5.jpg" },
        { image: "/assets/allimages/IMG_9024.JPG" },
        { image: "/assets/allimages/IMG_9032.JPG" },



    ]
    return (
        <>
            <Carousel>
                {images.map((item, index) => {
                    return (


                        <Carousel.Item key={index} style={{ height: "400px" }}>
                            <img
                                className="d-block w-100"
                                src={item.image}
                                alt="Slide image alt"
                                style={{ height: "100%", objectFit: "cover" }}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
            <Container className='mb-5'>
                <Row>
                    <div className="section-title-2" >
                        <h2>
                        Transportation       </h2>
                    </div>
                    <Col lg={7}>
                        <p>Alpha Motors is a one of the leading logistics company in Oman providing end-to-end transport and cargo movement solution.</p>
                        <p>Alpha Motors logistics owns and operates a large fleet that includes prime movers, cranes, trucks, variety of trailers (flat-bed, low-bed, long-bed, triple axle and air suspension). Our expertise in transport management and planning allows us to design a solution that meets your needs and also quickly respond to any event disruptions.</p>
                          


                    </Col>

                    <Col lg={5}>
                        <div>
                            <img src='/assets/allimages/IMG_9017_450x300.jpg' width={'100%'} />   
                       
                        </div>
                    </Col>
                    
                </Row>

              
            </Container>
            <Footer />

        </>
    )
}

export default Transportation
