
import React, { useEffect, useState } from 'react'
import Homepage from './Homepage'
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Navbars() {
  const [isVisible, setIsVisible] = useState('fixed-normal');

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible('fixed-top');
      } else {
        setIsVisible('fixed-normal');
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Scroll to top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const menus = [
    { name: "home", link: "/" },
    { name: "about us", link: "/about" },
    {
      name: 'services', link: '/services', subMenu: [
        { name: 'Warehouse', link: '/warehouse' },
        { name: "Yard", link: '/yard' },
        { name: 'Transportation', link: '/transportation' },
        // { name: 'Offered', link: '/offered' },
      ]
    },
    { name: "Gallery", link: '/gallery' },
    { name: 'contact', link: '/contact' }
  ];
  return (
    <div>
      <header id="header" className="header scroll-to-top">
        <div className="topbar d-flex align-items-center">
          <div className="container d-flex justify-content-center justify-content-md-between">
            {/* <div className="contact-info d-flex align-items-center">
              <i className="bi bi-envelope d-flex align-items-center">
                <a href="mailto:contact@example.com">contact@example.com</a>
              </i>
              <i className="bi bi-phone d-flex align-items-center ms-4">
                <span>+91 000 000 00</span>
              </i>
            </div> */}
            <div className="social-links d-none d-md-flex align-items-center">
              <a href="#" className="twitter">
                <i className="bi bi-twitter-x" />
              </a>
              <a href="#" className="facebook">
                <i className="bi bi-facebook" />
              </a>
              <a href="#" className="instagram">
                <i className="bi bi-instagram" />
              </a>
              <a href="#" className="linkedin">
                <i className="bi bi-linkedin" />
              </a>
            </div>
          </div>
        </div>
        {/* End Top Bar */}
        {/* <div className="branding d-flex align-items-cente">
          <div className="container position-relative d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center">
              <h1 className="sitename">Alpha Motors</h1>
              <span>.</span>
            </a>
            <nav id="navmenu" className="navmenu">
              <ul>
                <li>
                  <a href="/" className="active">
                    Home
                    <br />
                  </a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <a href="#portfolio">Portfolio</a>
                </li>
                <li>
                  <a href="#team">Team</a>
                </li>
                <li>
                  <a href="blog.html">Blog</a>
                </li>
                <li className="dropdown">
                  <a href="#">
                    <span>Dropdown</span>{" "}
                    <i className="bi bi-chevron-down toggle-dropdown" />
                  </a>
                  <ul>
                    <li>
                      <a href="#">Dropdown 1</a>
                    </li>
                    <li className="dropdown">
                      <a href="#">
                        <span>Deep Dropdown</span>{" "}
                        <i className="bi bi-chevron-down toggle-dropdown" />
                      </a>
                      <ul>
                        <li>
                          <a href="#">Deep Dropdown 1</a>
                        </li>
                        <li>
                          <a href="#">Deep Dropdown 2</a>
                        </li>
                        <li>
                          <a href="#">Deep Dropdown 3</a>
                        </li>
                        <li>
                          <a href="#">Deep Dropdown 4</a>
                        </li>
                        <li>
                          <a href="#">Deep Dropdown 5</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Dropdown 2</a>
                    </li>
                    <li>
                      <a href="#">Dropdown 3</a>
                    </li>
                    <li>
                      <a href="#">Dropdown 4</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
              <i className="mobile-nav-toggle d-xl-none bi bi-list" />
            </nav>
          </div>
        </div> */}

        <Navbar expand="lg" className={`navbar-color ${isVisible}`}>
          <Container className='container'>
            <Navbar.Brand as={Link} to="/">
              {/* <h3 className='sitename'>Alpha Motors</h3> */}
              <div>
                <img src='/assets/logo/Alpha_FreeZone_Logo_Web-01.png' width={'100%'} height={'100%'} />

              </div>
              {/* <div><h1>Alpha Motors</h1></div> */}

            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav"  >
              <Nav className="me-auto">
                {menus.map((item, index) => (
                  item.subMenu ? (
                    <NavDropdown title={item.name} id={`nav-dropdown-${index}`} key={index}>
                      {item.subMenu.map((subitem, subindex) => (
                        <NavDropdown.Item as={Link} to={subitem.link} key={subindex}>{subitem.name}</NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  ) : (
                    <Nav.Link as={Link} to={item.link} key={index}>{item.name}</Nav.Link>
                  )
                ))}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>



      </header>

      {/* <Navbar expand="lg mobile-nav" className="bg-body-tertiary" fixed='top'>
      <Container>
        <Navbar.Brand href="/">                <img src='/assets/logo/Alpha_FreeZone_Logo_Web-01.png' width={'100%'} height={'100%'} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
                {menus.map((item, index) => (
                  item.subMenu ? (
                    <NavDropdown title={item.name} id={`nav-dropdown-${index}`} key={index}>
                      {item.subMenu.map((subitem, subindex) => (
                        <NavDropdown.Item as={Link} to={subitem.link} key={subindex}>{subitem.name}</NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  ) : (
                    <Nav.Link as={Link} to={item.link} key={index}>{item.name}</Nav.Link>
                  )
                ))}
              </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar> */}

    </div>
  )
}

export default Navbars
