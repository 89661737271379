import React from 'react'

function Footer() {
  return (
    <div>
      <footer id="footer" className="footer accent-background">
        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-12 footer-about">
              <a href="index.html" className="logo d-flex align-items-center">
                <span className="sitename">Alpha Motors</span>
              </a>
              <p style={{paddingRight:'50px'}}>
                Alpha Motors Trading and Freezone Company LLC is an allied business of Alpha Motors LLC.
              </p>
              <div className="social-links d-flex mt-4">
                <a href="">
                  <i className="bi bi-twitter-x" />
                </a>
                <a href="">
                  <i className="bi bi-facebook" />
                </a>
                <a href="">
                  <i className="bi bi-instagram" />
                </a>
                <a href="">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About us</a>
                </li>
                <li>
                  <a href="/yard">Services</a>
                </li>
                <li>
                  <a href="#">Terms and Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6 footer-links" style={{paddingLeft:'50px'}}>
              <h4>Our Services</h4>
              <ul>
                <li>
                  <a href="/yard">Yard</a>
                </li>
                <li>
                  <a href="/warehouse">Warehouse</a>
                </li>
                <li>
                  <a href="/transportation">Transportation</a>
                </li>
                {/* <li>
                  <a href="/offered">Offered</a>
                </li> */}

              </ul>
            </div>
            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <strong>Address:</strong>
              <p>Alpha Motors Trading and Free Zone Company LLC, FGCV+XR8, Liwa, Oman</p>
              <p className="mt-4">
                <strong>Phone:</strong> <span>+968 000 000 00</span>
              </p>
              <p>
                <strong>Email:</strong> <span>info@example.com</span>
              </p>
            </div>
          </div>
        </div>
        <div className="container copyright text-start mt-4">
          <p style={{fontSize:"12px"}}>
            © <span>Copyright</span> <strong className="px-1 sitename">Alpha Motors</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div className="credits">
            {/* All the links in the footer should remain intact. */}
            {/* You can delete the links only if you've purchased the pro version. */}
            {/* Licensing information: https://bootstrapmade.com/license/ */}
            {/* Purchase the pro version with working PHP/AJAX contact form: [buy-url] */}
            {/* Designed by  */}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
