import React from 'react'
import Footer from './Footer'
import { Carousel, Row, Col } from 'react-bootstrap'

function About() {

  const images = [
    { image: "/assets/allimages/5.jpg" },
    { image: "/assets/allimages/2.JPG" },
    { image: "/assets/allimages/IMG_9033.JPG" },



  ]
  return (
    <>
      {/* <div className="waveWrapper waveAnimation">
        <div className="waveWrapperInner bgTop">
          <div
            className="wave waveTop"
            style={{ backgroundImage: "url('http://front-end-noobs.com/jecko/img/wave-top.png')" }}
          ></div>
        </div>
        <div className="waveWrapperInner bgMiddle">
          <div
            className="wave waveMiddle"
            style={{ backgroundImage: "url('http://front-end-noobs.com/jecko/img/wave-mid.png')" }}
          ></div>
        </div>
        <div className="waveWrapperInner bgBottom">
          <div
            className="wave waveBottom"
            style={{ backgroundImage: "url('http://front-end-noobs.com/jecko/img/wave-bot.png')" }}
          ></div>
        </div>
      </div> */}
      <Carousel>
        {images.map((item, index) => {
          return (


            <Carousel.Item key={index} style={{ height: "400px" }}>
              <img
                className="d-block w-100"
                src={item.image}
                alt="Slide image alt"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </Carousel.Item>
          )
        })}
      </Carousel>
      <div className=''>


        <section id="about" className="about section ">

          <div className="container section-title-inside" >
            <h2>About Us</h2>

            <Row>
              <Col lg={7} className='mt-5'>
              <div className='about-div-p'>
              <p>  Alpha Motors and Trading (FZC) LLC, part of Bahwan International Group (BIG),
                is an epitome of automotive and logistics excellence. With state-of-the-art
                facilities and a customer-centric approach, we redefine industry standards,
                bolstered by BIG's longstanding legacy in Oman and the GCC
                Our vehicle yard at Alpha Motors and Trading (FZC) LLC serves as the nerve
                center of our automotive operations, embodying efficiency, organization, and
                security.
                </p>
                <p>Spanning across a sprawling expanse, our vehicle yard provides a dedicated
                space for the storage, staging, and management of vehicles of all types and
                sizes. From sleek sedans to rugged trucks, our yard accommodates a diverse
                range of vehicles, ensuring they are housed in a secure and controlled
                environment.</p>
                <p>
                Equipped with state-of-the-art infrastructure and modern amenities, our yard is
                designed to optimize operational workflows and streamline logistics processes.
                Advanced tracking systems and inventory management technologies enable
                real-time monitoring of vehicle movements, ensuring precision and accuracy in
                inventory control.</p>
                <p>
                Moreover, our vehicle yard adheres to stringent security protocols to safeguard
                our valuable assets. 7/24 surveillance, access control measures, and robust
                perimeter security mechanisms ensure that our vehicles are protected against
                unauthorized access and theft, providing peace of mind to both our clients and
                stakeholders.</p>
                <p>
                At Alpha Motors and Trading (FZC) LLC, our vehicle yard is not just a storage
                space—it's a testament to our commitment to excellence in automotive
                logistics. With its efficiency, organization, and security, our yard serves as the
                foundation upon which we build seamless and reliable transportation solutions
                for our valued customers.</p>
                </div>
              </Col>
              <Col lg={5} className='mt-5'>
              <div className='about-image-2 '>
              <img  src='/assets/allimages/IMG_9019_450x300.jpg' width={'100%'}/>
              </div>
              <div className='about-image-1 mt-4'>
              <img  src='/assets/allimages/IMG_9017_450x300.jpg' width={'100%'}/>
              </div>
              
              
              </Col>
            </Row>
          </div>
        </section>
        <Footer />


      </div>
    </>
  )
}

export default About
