import React from 'react'
import Footer from './Footer'
import { Row, Col } from 'react-bootstrap'

function Contact() {
  return (
    <div>
      <section id="contact" className="contact section">
        {/* Section Title */}
        {/* <div className="container section-title-2 text-center" >
          <h2 className='text-center'>Contact</h2>

        </div> */}

        <div className="container  mb-5" style={{ paddingBottom: "0px" }} >
              <h2 className='for-details-h2 text-center' style={{fontSize:'24px'}}>
                For details & Enquiry, please contact
              </h2>

            </div>

            <div className="col-lg-12  for-details mb-5" style={{padding:"0px 75px"}}>

              <div className="info-container d-flex flex-column align-items-center justify-content-center">

                <Row style={{ width: '100%' }}>

                  <Col lg={4}>
                    <div
                      className="info-item d-flex"

                    >
                      <i className="bi bi-person-badge-fill" />
                      <div>
                        <h3>Mr. Ramakrishnan</h3>
                        <p>Mobile : +968 9213 2264</p>
                        <p>Email : ramakrishnan@sbigh.com</p>

                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div
                      className="info-item d-flex"

                    >
                      <i className="bi bi-person-badge-fill" />
                      <div>
                        <h3>Mr. Manimaran
                        </h3>
                        <p>Mobile : +968 9798 8117
                        </p>
                        <p>Email : manimaran@sbigh.com</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div
                      className="info-item d-flex"

                    >
                      <i className="bi bi-person-badge-fill" />
                      <div>
                        <h3>Mr. Sankar Narayan</h3>
                        <p>Mobile : +968 9550 1917</p>
                        <p>Email : sfzwhobgs@sbigh.com</p>
                      </div>

                    </div>
                  </Col>
                </Row>


              </div>
            </div>
        {/* End Section Title */}
        <div className="container" >
          <div className="row gx-lg-0 gy-4">
            <div className="col-lg-4">
              <div className="info-container d-flex flex-column align-items-center justify-content-center">
                <div
                  className="info-item d-flex"

                >
                  <i className="bi bi-geo-alt flex-shrink-0" />
                  <div>
                    <h3>Address</h3>
                    <p>Alpha Motors Trading and Free Zone Company LLC, FGCV+XR8, Liwa, Oman</p>
                  </div>
                </div>
                {/* End Info Item */}
                <div
                  className="info-item d-flex"

                >
                  <i className="bi bi-telephone flex-shrink-0" />
                  <div>
                    <h3>Call Us</h3>
                    <p>+968 000 0000 00</p>
                  </div>
                </div>
                {/* End Info Item */}
                <div
                  className="info-item d-flex"

                >
                  <i className="bi bi-envelope flex-shrink-0" />
                  <div>
                    <h3>Email Us</h3>
                    <p>info@example.com</p>
                  </div>
                </div>
                {/* End Info Item */}
                <div
                  className="info-item d-flex"

                >
                  <i className="bi bi-clock flex-shrink-0" />
                  <div>
                    <h3>Open Hours:</h3>
                    <p>Sun-Thur: 8AM - 7PM</p>
                  </div>
                </div>
                {/* End Info Item */}
              </div>
            </div>
            <div className="col-lg-8">
              <form
                action="forms/contact.php"
                method="post"
                className="php-email-form"

              >
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required=""
                    />
                  </div>
                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required=""
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required=""
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={8}
                      placeholder="Message"
                      required=""
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
            



            <div className='col-lg-12 mt-5'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14525.503663157622!2d56.53428343147726!3d24.472428681042697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8b3591d61787bf%3A0x99cfd0d6d3eb635c!2sAlpha%20Motors%20Trading%20and%20Free%20Zone%20Company%20LLC!5e0!3m2!1sen!2sin!4v1723551846828!5m2!1sen!2sin" width={'100%'} height={'300px'}></iframe>
            </div>
            {/* End Contact Form */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Contact
