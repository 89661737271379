import React from 'react'
import Footer from '../Footer'
import { Container, Row, Col, Carousel } from 'react-bootstrap';
function Warehouse() {

    const service = [
        {
            id: 1, name: "Warehouse", links: [
                { name: 'Warehouse', link: '/warehouse' },
                { name: "Yard", link: '/yard' },
                { name: 'Transportation', link: '/transportation' },
                { name: 'Offered', link: '/offered' },


            ]
        }
    ]

    const images = [
      
        { image: "/assets/allimages/1.jpg" },
        { image: "/assets/allimages/2.JPG" },
        { image: "/assets/allimages/3.JPG" },



    ]
    return (
        <>
            <Carousel>
                {images.map((item, index) => {
                    return (


                        <Carousel.Item key={index} style={{ height: "400px" }}>
                            <img
                                className="d-block w-100"
                                src={item.image}
                                alt="Slide image alt"
                                style={{ height: "100%", objectFit: "cover" }}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
            <Container className='mb-5'>
                <Row>
                    <div className="section-title-2" >
                        <h2>
                            Vehicle Yard Sohar Freezone
                        </h2>
                    </div>

                    <Col lg={7}>
                        <div className='cheacks-div'>

                            <p ><i class="bi bi-check"></i> 4600 Shaded Parking</p>
                            <p ><i class="bi bi-check"></i> 500 Open area Parking</p>
                            <p ><i class="bi bi-check"></i> Asphalted with bay marked parking area</p>
                            <p ><i class="bi bi-check"></i> Stockyard secured with full perimeter fence with security and CCTV coverage 7/24</p>
                            <p ><i class="bi bi-check"></i> Propriety Insurance covered</p>
                            <p><i class="bi bi-check"></i> Ramp & forklift facility for destuffing and stripping for Container shipments</p>
                            <p><i class="bi bi-check"></i> Value added Service</p>
                        </div>
                        <div className="section-title-3 mt-3" >
                            <h2>
                                Valued added
                                Services        </h2>
                        </div>
                        <div className='cheacks-div'>
                            <p><i class="bi bi-check"></i> Transportation from Port to Freezone and to Pan Oman & UAE</p>
                            <p><i class="bi bi-check"></i> Fueling for new vehicles</p>
                            <p><i class="bi bi-check"></i> Steering wheel Wrapping and Protective seat covers</p>
                            <p><i class="bi bi-check"></i> RFID / Barcoding on vehicles</p>
                            <p><i class="bi bi-check"></i> On Arrival Quality Inspection</p>
                            <p><i class="bi bi-check"></i> Vehicle wheel rotation</p>
                            <p><i class="bi bi-check"></i> Air Inflation as per storage standards</p>
                            <p><i class="bi bi-check"></i> Battery Maintenance</p>
                            <p><i class="bi bi-check"></i> Chrome Protective Application</p>
                            <p><i class="bi bi-check"></i> Monthly Inventory</p>
                            <p><i class="bi bi-check"></i> PDI and Accessories fitments</p>
                            <p><i class="bi bi-check"></i> Any other support on Customer demand</p>
                            <p><i class="bi bi-check"></i> Customs clearance for Import and Export</p>
                            <p><i class="bi bi-check"></i> Transportation from port to freezone and freezone to port, Oman, and UAE</p>

                        </div>
                    </Col>
                    <Col lg={5}>
                        <div>
                            <img src='/assets/allimages/Yard_450x300.jpg' width={'100%'} />
                        </div>
                        <div className='mt-4'>
                            <img src='/assets/allimages/IMG_9022_450x300.JPG' width={'100%'} />
                        </div>
                    </Col>

                </Row>

                {/* <Row>
                    <Col lg={7} className='mt-4 cheack-tags'>
                      

                    </Col>

                    <Col lg={5} className='mt-4'>
                       
                        <div className='mt-4'>
                            <img src='/assets/allimages/2.JPG' width={'100%'} />
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col lg={5} className='mt-4'>
                        <div>
                            <img src='/assets/allimages/3.JPG' width={'100%'} />
                        </div>

                    </Col>

                    <Col lg={7} className='mt-4 cheack-tags'>
                        <div className="section-title-3" >
                            <h2>
                                Salient Features
                                of Pre-delivery
                                Inspection      </h2>
                        </div>
                        <div className='cheacks-div'>

                            <p><i class="bi bi-check"></i> On Arrival Inspection</p>
                            <p><i class="bi bi-check"></i> 7 Washing Bays and 5 Lifts</p>
                            <p><i class="bi bi-check"></i> Dedicated inspection area</p>
                            <p><i class="bi bi-check"></i> Paint booth</p>
                            <p><i class="bi bi-check"></i> Well trained technicians</p>
                            <p><i class="bi bi-check"></i> Test Track</p>
                            <p><i class="bi bi-check"></i> Water wash area</p>
                        </div>


                    </Col>
                </Row>

            </Container>
            <Footer />

        </>
    )
}

export default Warehouse
