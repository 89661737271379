import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import Footer from './Footer';

const images = [
  { image: "/assets/allimages/IMG_9022.JPG" },
  { image: '/assets/allimages/IMG_9024.JPG' },
  { image: '/assets/allimages/IMG_9025.JPG' },
  { image: '/assets/allimages/IMG_9034.JPG' },
  { image: '/assets/allimages/IMG_9027.JPG' },
  { image: '/assets/allimages/IMG_9028.JPG' },
  { image: '/assets/allimages/IMG_9030.JPG' },
  { image: '/assets/allimages/IMG_9031.JPG' },
  { image: '/assets/allimages/IMG_9032.JPG' },
  { image: '/assets/allimages/IMG_9033.JPG' },
  { image: '/assets/allimages/IMG_9034.JPG' }
];

const LightboxExample = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightboxAtIndex = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <>
      <div className="section-title-inside text-center mt-5 mb-5">
        <h2 style={{ marginBottom: '0px' }}>
          Our Gallery
        </h2>
      </div>
      <div className="gallery mb-5">
        {images.map((item, index) => (
          <img
            key={index}
            src={item.image}
            alt={`Gallery Image ${index}`}
            style={{ cursor: 'pointer', margin: '10px' }}
            onClick={() => openLightboxAtIndex(index)}
          />
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].image}
          nextSrc={images[(photoIndex + 1) % images.length].image}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].image}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
      <Footer />
    </>
  );
};

export default LightboxExample;
