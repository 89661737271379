import React from "react";
import Slider from "react-slick"; // Import Slider from react-slick
import "slick-carousel/slick/slick.css"; // Import Slick CSS
import "slick-carousel/slick/slick-theme.css"; // Import Slick theme CSS

const WelcomeSection = () => {
  const settings = {
    dots: true, // Enable scrolling dots
    infinite: true, // Loop through slides
    speed: 600, // Speed of transition
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Autoplay delay in milliseconds
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (

    <div className="container">
      <div className="section-title mt-5" >
        <h2>About Us</h2>

      </div>
      <div className="row align-items-center justify-content-between">
        <div className="col-lg-6 mb-5 mb-lg-0 order-lg-2">
          <Slider {...settings}>
            <div style={{ height: "600px" }}>
              <img src="/assets/allimages/Yard.jpg" alt="Image 1" className="img-fluid" style={{ height: "100%", objectFit: "cover" }} />
            </div>
            <div style={{ height: "600px" }}>
              <img src="/assets/allimages/about-home2.png" alt="Image 2" className="img-fluid" style={{ height: "100%", objectFit: "cover" }} />
            </div>
            <div style={{ height: "600px" }}>
              <img src="/assets/allimages/about-home3.png" alt="Image 3" className="img-fluid" style={{ height: "100%", objectFit: "cover" }} />
            </div>
          </Slider>
        </div>
        <div className="col-lg-6 order-lg-1" style={{paddingRight:"30px"}}>
          <span className="section-subtitle">Welcome</span>
          <h1 className="mb-4 alphamotors-abouts mt-3">Alpha Motors Trading and <br></br>Freezone Company LLC

          </h1>
          <p>
            Alpha Motors and Trading (FZC) LLC, part of Bahwan International Group (BIG), is an epitome of automotive and logistics excellence. With state-of-the-art facilities and a customer-centric approach, we redefine industry standards, bolstered by BIG's longstanding legacy in Oman and the GCC
          </p>
          <p className="mt-5">
            <a href="/about" className="get-about">Read...</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;
